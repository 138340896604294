<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7503 13.9165L13.917 12.7498L10.8337 9.6665V5.83317H9.16699V10.3332L12.7503 13.9165ZM10.0003 18.3332C8.84755 18.3332 7.76421 18.1144 6.75033 17.6769C5.73644 17.2394 4.85449 16.6457 4.10449 15.8957C3.35449 15.1457 2.76074 14.2637 2.32324 13.2498C1.88574 12.2359 1.66699 11.1526 1.66699 9.99984C1.66699 8.84706 1.88574 7.76373 2.32324 6.74984C2.76074 5.73595 3.35449 4.854 4.10449 4.104C4.85449 3.354 5.73644 2.76025 6.75033 2.32275C7.76421 1.88525 8.84755 1.6665 10.0003 1.6665C11.1531 1.6665 12.2364 1.88525 13.2503 2.32275C14.2642 2.76025 15.1462 3.354 15.8962 4.104C16.6462 4.854 17.2399 5.73595 17.6774 6.74984C18.1149 7.76373 18.3337 8.84706 18.3337 9.99984C18.3337 11.1526 18.1149 12.2359 17.6774 13.2498C17.2399 14.2637 16.6462 15.1457 15.8962 15.8957C15.1462 16.6457 14.2642 17.2394 13.2503 17.6769C12.2364 18.1144 11.1531 18.3332 10.0003 18.3332ZM10.0003 16.6665C11.8475 16.6665 13.4205 16.0172 14.7191 14.7186C16.0177 13.42 16.667 11.8471 16.667 9.99984C16.667 8.15261 16.0177 6.5797 14.7191 5.28109C13.4205 3.98248 11.8475 3.33317 10.0003 3.33317C8.1531 3.33317 6.58019 3.98248 5.28158 5.28109C3.98296 6.5797 3.33366 8.15261 3.33366 9.99984C3.33366 11.8471 3.98296 13.42 5.28158 14.7186C6.58019 16.0172 8.1531 16.6665 10.0003 16.6665Z"
      fill="url(#paint0_linear_649_1289)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_649_1289"
        gradientUnits="userSpaceOnUse"
        x1="1.66699"
        x2="19.7181"
        y1="1.6665"
        y2="3.33249"
      >
        <stop stop-color="#F9D416" />
        <stop offset="0.536458" stop-color="#F08379" />
        <stop offset="1" stop-color="#9B4765" />
      </linearGradient>
    </defs>
  </svg>
</template>
