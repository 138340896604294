<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99967 9.99984C10.458 9.99984 10.8504 9.83664 11.1768 9.51025C11.5031 9.18386 11.6663 8.7915 11.6663 8.33317C11.6663 7.87484 11.5031 7.48248 11.1768 7.15609C10.8504 6.8297 10.458 6.6665 9.99967 6.6665C9.54134 6.6665 9.14898 6.8297 8.82259 7.15609C8.4962 7.48248 8.33301 7.87484 8.33301 8.33317C8.33301 8.7915 8.4962 9.18386 8.82259 9.51025C9.14898 9.83664 9.54134 9.99984 9.99967 9.99984ZM9.99967 16.1248C11.6941 14.5693 12.9511 13.1561 13.7705 11.8853C14.59 10.6144 14.9997 9.48595 14.9997 8.49984C14.9997 6.98595 14.517 5.74636 13.5518 4.78109C12.5865 3.81581 11.4025 3.33317 9.99967 3.33317C8.5969 3.33317 7.41287 3.81581 6.44759 4.78109C5.48231 5.74636 4.99967 6.98595 4.99967 8.49984C4.99967 9.48595 5.4094 10.6144 6.22884 11.8853C7.04829 13.1561 8.30523 14.5693 9.99967 16.1248ZM9.99967 18.3332C7.76356 16.4304 6.09342 14.663 4.98926 13.0311C3.88509 11.3991 3.33301 9.88873 3.33301 8.49984C3.33301 6.4165 4.00315 4.75678 5.34342 3.52067C6.6837 2.28456 8.23579 1.6665 9.99967 1.6665C11.7636 1.6665 13.3156 2.28456 14.6559 3.52067C15.9962 4.75678 16.6663 6.4165 16.6663 8.49984C16.6663 9.88873 16.1143 11.3991 15.0101 13.0311C13.9059 14.663 12.2358 16.4304 9.99967 18.3332Z"
      fill="url(#paint0_linear_649_1291)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_649_1291"
        gradientUnits="userSpaceOnUse"
        x1="3.33301"
        x2="17.8179"
        y1="1.6665"
        y2="2.73599"
      >
        <stop stop-color="#F9D416" />
        <stop offset="0.536458" stop-color="#F08379" />
        <stop offset="1" stop-color="#9B4765" />
      </linearGradient>
    </defs>
  </svg>
</template>
